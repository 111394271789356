import React, { useEffect } from "react";




export default function Service() {
    useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);


    // const handleSubmit = (event) => {
    //     alert('Form Submitted');
    // };


    return (
        <>
            <div className="divider-wrap">
                <h1>SERVICE REQUEST</h1>
            </div>
            <div id="form-wrap">
                <div id="form-inner">
                    <form name='service-request' onSubmit="submit" method="POST" data-netlify="true" enctype="multipart/form-data">
                        <input type="hidden" name="form-name" value="service-request"></input>
                        <div className="input-wrap">
                            <input
                                type="text"
                                placeholder="Owner Name"
                                name="owner-name"
                                className="small-text-input"
                                required
                            ></input>
                        </div>
                        <div className="input-wrap">
                            <input
                                type="text"
                                placeholder="Address"
                                name="address"
                                className="small-text-input"
                                required
                            ></input>
                        </div>
                        <div className="input-wrap">
                            <input
                                type="text"
                                placeholder="Phone"
                                name="phone"
                                className="small-text-input"
                                required
                            ></input>
                        </div>
                        <div className="input-wrap">
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                className="small-text-input"
                                required
                            ></input>
                        </div>
                        <div className="input-wrap">
                            <textarea
                                type="text"
                                name="description"
                                placeholder="Description of Issue"
                                className="large-text-input"
                                required
                            ></textarea>
                        </div>
                        <div className="input-wrap" id="file-wrap">
                            <label>
                                <span>Please include short video or photo (limit 8MB):</span>
                                <input name="file" type="file" required/>
                            </label>
                        </div>
                        <div id="button-wrap">
                            <button type="submit" id="submit-button">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}