import React from 'react'
import Logo from '../images/Insïght LOGO medium lines.jpg'
import Tabs from '../Components/Tabs'
import GloLogo from '../images/gloLogoNew.jpg'
import AirluxLogo from '../images/airluxLogo.png'

export default function MobileNav(props) {
  return(
    <div className='mobile-nav-wrap'>
    <img src={Logo} className='mobile-logo' alt="logo" />
    <div className='headText'>
      <h2 className=''>Your full-service Utah dealer for Glo and Air-Lux window and door products</h2>
      <div id="small-logo-wrap">
        <a href="https://glowindows.com/">
          <img src={GloLogo} className="small-logo-img" alt="Glo Logo" />
        </a>
        <a href="https://www.air-lux.com/">
          <img src={AirluxLogo} className="small-logo-img" alt="Air-lux Logo" />
        </a>
      </div>
    <div className='mobile-tab-wrap'>
      <Tabs active={props.active} setSelected={props.setSelected} tabs={props.tabs} />
    </div>
    </div>
  </div>
  );
}