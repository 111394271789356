import React, { useEffect } from 'react'
import FileCard from './FileCard';
import Footer from './Footer';

export default function Technical() {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  return (
    <>
      <div className='divider-wrap'>
        <h1>Glo Detail Drawings & Information</h1>
      </div>
      <div className='technical-wrap'>
        <FileCard
          href=''
          series='A5 Double Pane Windows'
          links={{
            "A5 Standard Frame Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/09/A5-Double-Pane-Detail-Set-Windows.pdf",
            "A5f Nail Flange Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/09/A5f-Double-Pane-Detail-Set-Windows.pdf",
            "A5h Hidden Sash Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/09/A5h-Double-Pane-Detail-Set-Windows.pdf"
          }} />
        <FileCard
          href=''
          series='A5 Triple Pane Windows'
          links={{
            "A5 Standard Frame Detail Drawings": "https://glowindows.com/wp-content/uploads/2017/08/A5-Triple-Detail-Set-Windows.pdf",
            "A5f Nail Flange Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/09/A5f-Triple-Detail-Set-Windows.pdf",
            "A5h Hidden Sash Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/09/A5h-Triple-Detail-Set-Windows.pdf"
          }} />
        <FileCard
          href=''
          series='A7 Triple Pane Windows'
          links={{
            "A7 Standard Frame Detail Drawings": "https://glowindows.com/wp-content/uploads/2020/10/A7-Triple-Pane-Detail-Set-Windows.pdf",
            "A7 Aluminum Tilt Turn Windows - Passive House Institute US Certification": "https://glowindows.com/wp-content/uploads/2017/05/GLO-A7-TT-WINDOW-PHIUS.pdf",
            "A7 Aluminum Fixed Windows - Passive House Institute US Certification": "https://glowindows.com/wp-content/uploads/2017/05/GLO-A7-FIXED-WINDOW-PHIUS.pdf"
          }} />
        <FileCard
          href=''
          series='A7 Double Pane & Triple Pane Doors'
          links={{
            "A7 Double Pane Door Frame Detail Drawings": "https://glowindows.com/wp-content/uploads/2021/03/A7-Double-Pane-Detail-Set-Doors.pdf",
            "A7 Triple Pane Door Frame Detail Drawings": "https://glowindows.com/wp-content/uploads/2021/03/A7-Triple-Pane-Detail-Set-Doors.pdf"
          }} />
        <FileCard
          href=''
          series='LS Ultra Lift and Slide Doors'
          links={{
            "LSu Standard Lift and Slide Detail Drawings": "https://glowindows.com/wp-content/uploads/2021/01/LSu-Standard.pdf",
            "LSu Flush Lift and Slide Detail Drawings": "https://glowindows.com/wp-content/uploads/2021/01/LSu-Flush.pdf",
            "LSu ADA Lift and Slide Detail Drawings": "https://glowindows.com/wp-content/uploads/2021/01/LSu-ADA.pdf"
          }} />
      </div>
      <div className='divider-wrap'>
        <h1>Air-lux Detail Drawings & Information</h1>
      </div>
      <div className='technical-wrap'>
        <FileCard
          href=''
          series='Opening Types'
          links={{
            "Opening Options / Planning Guide": "https://www.air-lux.com/fileadmin/air-lux/downloads/A-Technische-Daten-Downloads/01-Oeffnungsvarianten-Planungshilfe/air-lux_Opening_options_planning_help.pdf",
          }} />
        <FileCard
          href=''
          series='Sectional Plans & System Diagrams'
          links={{
            "Standard Window Drawings": "https://www.air-lux.com/fileadmin/air-lux/downloads/A-Technische-Daten-Downloads/06-Fenster-Schnitte/air-lux_Standard_window_drawings.pdf",
            "Standard Door Drawings": "https://www.air-lux.com/fileadmin/air-lux/downloads/A-Technische-Daten-Downloads/07-Tuer-Schnitte/air-lux_Standard_door_drawings.pdf"
          }} />
      </div>
      <div id='home-footer-two'>
        <Footer />
      </div>
    </>
  );
}