import React from "react";
import Card from "./Card";
import EmbedYoutube from './Youtube';
import Footer from "./Footer";
import { useEffect } from "react";


export default function Products() {
  useEffect(() => window.scrollTo({ top: 0, behavior: "smooth" }), []);
  return (
    <>
      <div className="content-wrap">
        <div className="divider-wrap">
          <h1>DOOR SOLUTIONS</h1>
        </div>
        <div className='divider-subhead'>
          <div className='divider-subtext'>
            <p>
              Glo European doors provide beauty, high performance, multiple design options and configurations. Our doors have excellent performance and quality. European hardware ensures smooth operation and impeccable style. Glo doors achieve better R values and offer sleek architectural lines that are ideal for contemporary and traditional projects.
            </p>
          </div>
          <div className='divider-line' />
        </div>
        <div className='card-container'>
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904349/T%20AND%20T%20BUILDERS/A5_door-r_hx1sdr.jpg'
            imageTitle="Glo A5 Door Series"
            href="https://glowindows.com/product/a5-aluminum-doors/"
            series="A5"
            title="Double Pane + Triple Pane Aluminum Doors"
            content="The A5 double pane and triple pane aluminum doors are the perfect union of cost effective efficiency and beauty. The sleek frame, thermal performance and European hardware is sure to be a great solution for an array of projects."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904351/T%20AND%20T%20BUILDERS/A7_door-r_jmvyo3.jpg'
            imageTitle="Glo A7 Door Series"
            href="https://glowindows.com/product/a7-triple-pane-aluminum-entry-doors/"
            series="A7"
            title="Triple Pane Aluminum Entry Doors"
            content="The A7 triple pane aluminum doors are the FIRST Passive House Certified doors in the United States. Comprised of a larger thermal break, multiple air seals, argon gas and low-e glass: Redefining the aluminum door experience."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904348/T%20AND%20T%20BUILDERS/LS_lift_and_slide_door-r_srbf4j.jpg'
            imageTitle="Glo Lift & Slide Door Series"
            href="https://glowindows.com/product/lift-and-slide-door/"
            series="LS"
            title="Lift and Slide Doors"
            content="Our modern Lift and Slide doors establish a new paradigm of style, precision, and durability sure to make a statement in any setting. Designed with quality and purpose, the lift and slide door is custom crafted to meet a wide variety of functional and aesthetic requirements while also delivering excellent performance."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904353/T%20AND%20T%20BUILDERS/PC_postless_corner_door-r_u8xm2q.jpg'
            imageTitle="Glo Postless Corner Door Series"
            href="https://glowindows.com/product/postless-corner-door/"
            series="PC"
            title="Postless Corner Doors"
            content="Highly customizable and inherently impressive, the Postless Corner Door exemplifies modern style and serves as a perfect complement to contemporary trends."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904350/T%20AND%20T%20BUILDERS/PD_air_lux_pivot_door-r_ncmb3g.jpg'
            imageTitle="Air Lux Pivot Door Series"
            href="https://glowindows.com/product/air-lux-pivot-door/"
            series="PD"
            title="Air-Lux Pivot Door"
            content="The ground-breaking Air-Lux Pivot Door provides a unique and enviable aesthetic without the typical drawbacks associated with pivoting entrance doors. The robust and dynamic hardware allows for incredibly large door panels to effortlessly rotate on a central or lateral axis, providing a show-stopping alternative to conventional doors at oversized entrance locations. A state-of-the-art air and […]"

          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904353/T%20AND%20T%20BUILDERS/SD_air_lux_sliding_door-r_bdnojr.jpg'
            imageTitle="Air Lux Sliding Door Series"
            href="https://glowindows.com/product/sliding-doors-air-lux/"
            series="SD"
            title="Air-Lux Sliding Doors"
            content="Once again upending the fenestration industry with superior design and construction, the Air-Lux Sliding Door leaves lesser door systems in the dust with its ability to span incredible dimensions and provide 100% impermeable water and air sealing. This patented sealing system combined with the ability to accommodate sliding leaf weights of up to nearly 4000lbs […]"
          />
        </div>
        <div className="divider-wrap">
          <h1>WINDOW SOLUTIONS</h1>
        </div>
        <div className='divider-subhead'>
          <div className='divider-subtext'>
            <p>
              Modern European windows available in thermally broken aluminum windows and pre-finished wood-aluminum windows in a multitude of configurations and styles. Every window includes a myriad of options, including over 300 color choices, European hardware, sleek modern lines, double or triple pane glazing, low-e glass, multiple air seals, and high performance spacers. Versatile style with unwavering energy efficiency, our European windows are the perfect solution for any home.
            </p>
          </div>
          <div className='divider-line' />
        </div>
        <div className='card-container'>
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904352/T%20AND%20T%20BUILDERS/A5_window-r_pr6zmo.jpg'
            imageTitle="Glo A5 Window Series"
            href="https://glowindows.com/product/a5-double-triple-pane-european-windows/"
            series="A5"
            title="Double Pane + Triple Pane Aluminum Windows"
            content="A high performance glazing unit with a narrow frame. The A5 double pane and triple pane aluminum windows provide modern aesthetic and thermal performance."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904345/T%20AND%20T%20BUILDERS/A7_window-r_mcyoku.jpg'
            imageTitle="Glo A7 Window Series"
            href="https://glowindows.com/product/a7-aluminum-triple-pane-windows/"
            series="A7"
            title="Triple Pane Aluminum Windows"
            content="An all aluminum frame with continuous insulation provides excellent durability and quality. The A7 triple pane aluminum windows achieve a greater level of energy performance and aesthetically clean architectural lines."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1655749787/Insight%20Windows/curtainWall_gng7mn.jpg'
            imageTitle="Glo CW Window Series"
            href="https://glowindows.com/product/curtain-wall-double-pane/"
            series="CW"
            title="Curtain Wall Double + Triple Pane Aluminum"
            content="Achieve eye-catching designs through innovative custom solutions with our Curtain Wall systems; available in both double and triple pane thermally-broken aluminum."
          />
          <Card
            image='https://res.cloudinary.com/grizzcode-llc/image/upload/v1616904347/T%20AND%20T%20BUILDERS/DW_air_lux_descending_window-r_ypnalh.jpg'
            imageTitle="Air Lux DW Window Series"
            href="https://glowindows.com/product/air-lux-descending-window/"
            series="DW"
            title="Air-Lux Descending Window"
            content="Air-Lux has created a new paradigm in fenestration products with the Descending Window. By designing a system in which the entire width of the window can be opened without the requirements of side pockets or stacked sliding panels, Air-Lux has once again shattered pre-conceived expectations and limitations in both the design and construction of exterior […]"
          />
        </div>
        <div className="divider-wrap">
          <h1>PRODUCT HIGHLIGHTS</h1>
        </div>
        <div className='project-content-wrap'>
          <EmbedYoutube source="https://www.youtube.com/embed/S7ARluoVDSw" height='600' width='800' />
          <EmbedYoutube source="https://www.youtube.com/embed/8UlBXOrn9Fo" height='600' width='800' />
        </div>
      </div>
      <div id='home-footer-two'>
        <Footer />
      </div>
    </>
  )
}
