import React from 'react'
import '../Components/card.scss'

export default function Card(props) {
  return (
    <>
      <div className='card-wrap'>
        <a href={props.href}>
          <img className='card-img' src={props.image} alt={props.title}/>
          <div className='card-content'>
            <h2 className='card-title'>{props.series}</h2>
            <h5 className='card-subtitle'>{props.title}</h5>
            <p className='card-text'>{props.content}</p>
          </div>
        </a>
      </div>
    </>
  )
}