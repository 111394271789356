import React, { useEffect } from 'react'
import HeroPic from '../images/heroImg.jpg'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeroPic2 from '../images/heroImg2.jpg'
import HeroPic3 from '../images/heroImg3.jpg'
import Footer from './Footer'


export default function Home() {
  useEffect(() => window.scrollTo({ top: 0 }), []);

  return (
    <>
      <div className="hero-wrapper">
        <div
          className="hero-img"
          style={{
            backgroundImage: `url(${HeroPic})`,
            zIndex: '1'
          }}
        />
      </div>
      <div className="block-divider">
        <p>
          Insight European Windows is a supplier and installer of premium contemporary window and door products meeting the uppermost architectural aesthetic requirements of discerning clients and architects. With our background in building
          luxury homes we are uniquely qualified to work with your project team to design and provide glazing or fenestration products that result in beautiful detailing and transitions
          between surfaces and architectural elements, exceptional thermal performance, and a fantastic owner experience.
        </p>
      </div>
      <div className="hero-wrapper">
        <div
          className="hero-img"
          style={{
            backgroundImage: `url(${HeroPic2})`,
            zIndex: '2'
          }}
        />
      </div>
      <div className="block-divider">
        <p>
          We provide service and guidance to support your project at three critical stages during the home construction process: design and supply, installation, and owner support.
        </p>
      </div>
      <div className="hero-wrapper">
        <div
          className="hero-img"
          style={{
            backgroundImage: `url(${HeroPic3})`,
            zIndex: '3'
          }}
        />
      </div>
      <div className='layout-wrap'>
        <div className='process-container'>
          <div className='process-text'>
            <h2>Design and Source</h2>
            <ul>
              <li>Discussion of design goals and review of products for best fit within desired design.</li>
              <li>Design collaboration with your architect to ensure exceptional detailing, fit, and finish.</li>
              <li>Interface with general contractor on important details and timing neccessary for smooth project implimentation.</li>
            </ul>
          </div>
        </div>
        <div className='process-container-light'>
          <div className='process-text'>
            <h2>Installation</h2>
            <ul>
              <li>Insight is heavily involved in the installation process, bringing prior experience as a general contractor utilizing these window and door packages in luxury custom homes.</li>
              <li>Pre-installation meeting to verify detailing and correct preparations for window and door installation.</li>
              <li>In-house installation service with a specially-trained crew that knows the tolerances and demands for correct installation of these products.</li>
            </ul>
          </div>
        </div>
        <div className='process-container' id='process-three'>
          <div className='process-text'>
            <h2>Service and Support</h2>
            <ul>
              <li>Insight European Windows has partnered with Glo and Air Lux because they are exceptional products, and we stand firmly behind the quality and performance offered.</li>
              <li>Insight provides continued service and adjustment after installation to ensure an exceptional ownership experience.</li>
              <li>Single point of contact means that we accept full responsibility for and are invested in the success and continued support of your door and window package.</li>
            </ul>
          </div>
        </div>
        <div id='home-footer'>
          <div id='statement-wrap'>
            <h4>
              As a successful General Contractor specializing in highly energy efficient custom mountain homes, we have the
              full-spectrum knowledge to ensure your project is as smooth and successful as possible from inception to completion
              and continued life cycle. We have worked with these windows and doors for several years, and it was a natural extension of
              our business to provide and service these incredibly well engineered European door and window packages.
            </h4>
          </div>
          <div id="quote-wrap">
            <FontAwesomeIcon icon={faReceipt} size="5x" color="#A9F4FF" />
            <div className="quote-text">
              Contact us for a consulation about window and door solutions for your project
            </div>
            <button className="quote-button" onClick={(e) => { window.location.href = 'mailto:travis@insighteuropeanwindows.com'; }}>SEND US YOUR PROJECT</button>
          </div>
        </div>
        <div id='home-footer-two'>
          <Footer />
        </div>
      </div>
    </>
  )
}

