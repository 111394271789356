import React from 'react'
import '../Components/card.scss'

export default function FileCard(props) {

  const files = Object.keys(props.links)
  const hrefs = Object.values(props.links)



  return (
    <>
      <div className='file-wrap'>
        <div className='file-content'>
          <h2 className='card-subtitle'>{props.series}</h2>
          {
            files.map(link => {
              const index = files.indexOf(link)
              return (
                <a key={link} href={hrefs[index]} className='tech-link' target="_blank" rel="noopener noreferrer">{link}</a>
              );
            })
          }
        </div>
     </div>
    </>

  );
}


        