import React from 'react';
import Logo from '../images/Insïght LOGO medium lines.jpg';
import Tabs from '../Components/Tabs'
import GloLogo from '../images/gloLogoNew.jpg'
import AirluxLogo from '../images/airluxLogo.png'


export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: "fade-out",
      tabFade: "fade-out",
      imgClass: "logo-img-startup",
      display: "none"
    }
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        width: "100%",
        imgClass: "logo-img",
        display: "flex"
      });
    }, 1000);

    setTimeout(() => {
      this.setState({
        fade: "fade-in",
      });
    }, 2100);

    setTimeout(() => {
      this.setState({
        tabFade: "fade-in",
      });
    }, 2700);

  }

  render() {
      return (
        <div className="head-container">
          <img src={Logo} className={this.state.imgClass} alt="logo" />
          <div className={this.state.tabFade}>
            <Tabs active={this.props.active} setSelected={this.props.setSelected} tabs={this.props.tabs} fade={this.state.tabFade} />
          </div>
          <div className={"headTextWrap " + this.state.fade} style={{ display: this.state.display }}>
            <h2 className="headText">Your full-service Utah dealer for Glo and Air-Lux window and door products</h2>
            <div id="small-logo-wrap">
              <a href="https://glowindows.com/">
                <img src={GloLogo} className="small-logo-img" alt="Glo Logo"/>
              </a>
              <a href="https://www.air-lux.com/">
                <img src={AirluxLogo} className="small-logo-img" alt="Air-lux Logo"/>
              </a>
            </div>
          </div>
        </div>
      )
    }
  }








